.App {
  text-align: center;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiBreadcrumbs-li:hover:not(.Mui-disabled) {
  cursor: pointer;
}


.stepcontainer {
  align-items: center;
  width: 100%;
  height: 100px;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 33%;
  float: left;
  font-size: 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #f87800;
}
.progressbar li:before {
  width: 50px;
  height: 50px;
  content: counter(step);
  counter-increment: step;
  line-height: 50px;
  border: 2px solid black;
  display: block;
  text-align: center;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  background-color: white;
  color: black;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: black;
  top: 25px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}

.stepcontent {
  margin-left: 10%;
  width: 80%;
  color: black;
  font-size: medium;
  text-align: left;
  text-transform: none;
  margin-top: 3%;
}
.btn-start {
  background-color: #f87800;
  color: white;
}

/* The sidenav */
.sidenav {
  width: 100%;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {visibility: hidden}
}

@media only screen and (max-width:369px) {
  .progressbar ul {
    float: left;
    width: 100% !important;
  }
  .progressbar li {
    width: 100% !important;
    position: unset !important;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width:490px) {
  .jumbotron img {
    max-height: 75px;
  }
  .Toastify__toast-container {
    width: 100vw !important;
  }
}

@media screen and (min-width: 768px) {
  .jumbotron h1, .jumbotron .h1 {
    font-size: 63px;
  }
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.wishlistImage {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .addToWishlistButtonMobile {
    display: none;
  }
  .wishlistItem {
    min-height: 115px;
  }
  .wishlistImage {
    text-align: left;
  }
}

footer {
  margin-top: auto;
  padding: 5px;
  text-align: center;
  height: 66px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Toastify__toast-theme--light {
  margin: 2px 5px 0 5px !important;
}


.overlay-custom-class-name {

}

.react-confirm-alert-body {
  width: 315px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.MuiDivider-root {
  border-color: rgba(211,211,211,0.5) !important;
}

.base__svg-container {
  width: auto;
}

.lineChartTest .base__container {
  margin: 10px 20px;
}
.lineChartTest.lineChartResponsive .base__container {
  margin: 0;
}

/*.image-gallery-image {*/
/*  max-width: 160px;*/
/*  max-height: 160px;*/
/*  width: auto;*/
/*  height: auto;*/
/*}*/
.image-gallery-thumbnail-image {
  max-width: 40px;
  max-height: 40px;
  width: auto;
  height: auto;
}
/*.image-gallery-swipe {*/
/*  max-height: 160px;*/
/*}*/

/*.image-gallery {*/
/*  width: 40%;*/
/*  height: auto;*/
/*}*/

/*.image-gallery-slide img {*/
/*  width: 100%;*/
/*  height: auto;*/
/*  max-height: 80vh;*/
/*  object-fit: cover;*/
/*  overflow: hidden;*/
/*  object-position: center center;*/
/*}*/



.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 14px !important;
  width: 14px !important;
}
.image-gallery-play-button {
  display: none;
}

img.image-gallery-image  {
  max-height: 360px !important;
}
.image-gallery-swipe {
  min-height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asdasd .image-gallery-swipe {
  min-height: unset;
}
.fullscreen .image-gallery-slide img {
  max-height: 100vh !important;
}

.image-gallery-thumbnail {
  width: 48px !important;
}

.image-gallery-thumbnail-image {
  width: auto !important;
}

.image-gallery-slide.left {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}