.base {
  &__container {
    position: relative;
    width: fit-content;
    margin: 20px;
  }

  &__svg-container {
    //background-color: aliceblue;
    //background-color: #ffffff;
    cursor: pointer;
  }

  &__gridlines {
    color: #cdebf9;
  }
  
  &__axis {
    //color: #72c6ed;
    color: #000000;
    stroke-width: 2;
    font-size: 12px;

    &-label {
      stroke: #72c6ed;
      font-size: 14px;
    }
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 0;
    //background-color: #cdebf9;
    background-color: rgb(248, 120, 0);
    //color: #45b3e7;
    color: #FFFFFF;
    //border: 1px solid #45b3e7;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 5px;
    width: fit-content;
    padding: 10px;
    opacity: 0;
  }
}